import { Amount } from '@/types'

export interface GetCollectionParam {
  id?: string
  handle?: string
  numberOfProducts?: number
  after?: string
  before?: string
  sortKey?: ProductCollectionSortKey
  reverse?: boolean
  filters?: ProductFilter[]
  productIds?: string[]
  productHandle?: string
}

export const ProductCollectionSortKeyBestSelling = 'BEST_SELLING'
export const ProductCollectionSortKeyDefault = 'COLLECTION_DEFAULT'
export const ProductCollectionSortKeyCreated = 'CREATED'
export const ProductCollectionSortKeyID = 'ID'
export const ProductCollectionSortKeyManual = 'MANUAL'
export const ProductCollectionSortKeyPrice = 'PRICE'
export const ProductCollectionSortKeyRelevance = 'RELEVANCE'
export const ProductCollectionSortKeyTitle = 'TITLE'

export type ProductCollectionSortKey =
  | typeof ProductCollectionSortKeyBestSelling
  | typeof ProductCollectionSortKeyDefault
  | typeof ProductCollectionSortKeyCreated
  | typeof ProductCollectionSortKeyID
  | typeof ProductCollectionSortKeyManual
  | typeof ProductCollectionSortKeyPrice
  | typeof ProductCollectionSortKeyRelevance
  | typeof ProductCollectionSortKeyTitle

export interface ProductFilter {
  productType?: string
  productVendor?: string
}

export interface CartLineItemInput {
  merchandiseId: string
  quantity: number
}

export type Attribute = {
  key: string
  value: string
}

export interface CreateCartInput {
  lines: CartLineItemInput[]
  attributes?: Attribute[]
}

export interface CartLinesAddInput {
  cartId: string
  lines: CartLineItemInput[]
}

export interface CartLinesUpdateInput {
  cartId: string
  lines: CartLineItemInput[]
}

export interface CartBuyerIdentityUpdateInput {
  buyerIdentity: any
  cartId: string
}

export interface CartDiscountCodesUpdateInput {
  cartId: string
  discountCodes: string[]
}

export interface CheckoutLineItemInput {
  variantId: string
  quantity: number
  customAttributes?: Attribute[]
}

export interface CheckoutLineItemUpdateInput {
  id: string
  quantity?: number
  customAttributes?: Attribute[]
}

export interface CreateCheckoutInput {
  customAttributes?: Attribute[]
  lineItems: CheckoutLineItemInput[]
}

export interface CheckoutLineItemsAddInput {
  checkoutId: string
  lineItems: CheckoutLineItemInput[]
}

export interface CheckoutLineItemsRemoveInput {
  checkoutId: string
  lineItemIds: string[]
}

export interface CheckoutLineItemsUpdateInput {
  checkoutId: string
  lineItems: CheckoutLineItemUpdateInput[]
}

export interface CheckoutAttributesUpdateInput {
  checkoutId: string
  input: {
    customAttributes?: Attribute[]
    note?: string
  }
}

export interface CheckoutDiscountCodeApplyInput {
  checkoutId: string
  discountCode: string
}

export interface CheckoutDiscountCodeRemoveInput {
  checkoutId: string
}

export interface CheckoutEmailUpdateInput {
  checkoutId: string
  email: string
}

export interface CompleteCheckoutInput {
  checkoutId: string
  payment: TokenizedPaymentInput
}

export interface UpdateCheckoutShippingAddressInput {
  checkoutId: string
  shippingAddress: MailingAddressInput
}

export interface UpdateCheckoutShippingLineInput {
  checkoutId: string
  shippingRateHandle: string
}

export interface TokenizedPaymentInput {
  billingAddress: MailingAddressInput
  idempotencyKey: string
  identifier?: string
  paymentAmount: Amount
  paymentData: string
  test: boolean
  type: PaymentTokenType
}

export type PaymentTokenType =
  | 'APPLE_PAY'
  | 'GOOGLE_PAY'
  | 'SHOPIFY_PAY'
  | 'STRIPE_VAULT_TOKEN'
  | 'VAULT'

export interface MailingAddressInput {
  address1: string
  address2: string
  city: string
  company?: string
  country: string
  firstName: string
  lastName: string
  phone: string
  province: string
  zip: string
}

export interface SearchaniseProductParams {
  suggestions?: boolean
  q?: string
  sortBy?: string
  sortOrder?: string
  categories?: boolean
  pages?: boolean
  output?: string
  facets?: boolean
  facetsShowUnavailableOptions?: boolean
  maxResults?: number
  startIndex?: number
  collection?: string
  [key: string]: string | boolean | undefined | number
}

export interface SearchaniseProductVariant {
  available: string
  barcode: string
  image_link: string
  link: string
  list_price: string
  options: { Style: string; Color: string; Size: string }
  price: string
  sku: string
  taxable: string
  variant_id: string
}

export interface SearchaniseSearchResult {
  categories?: {
    category_id: string
    image_link: string
    link: string
    title: string
  }[]
  items: {
    add_to_cart_id: string
    description: string
    discount: string
    image_link: string
    link: string
    list_price: string
    price: string
    product_code: string
    product_id: string
    product_type: string
    quantity: string
    shopify_images: string[]
    shopify_variants: SearchaniseProductVariant[]
    tags: string
    title: string
    vendor: string
  }[]
  itemsPerPage?: number
  pageStartIndex?: number
  pages?: {
    image_link: string
    link: string
    page_id: string
    title: string
  }[]
  suggestions?: string[]
  totalCategories?: number
  totalItems?: number
  totalPages?: number
  facets: any
}

export interface JudgeMeGetProductReviewsParams {
  url: string
  shop_domain: string
  product_id: string
  platform?: string
  page?: number
  per_page?: number
  sort_by?: string
  sort_dir?: 'asc' | 'desc' | ''
  ts: number
}

export interface JudgeMeGetProductReviewsResult {
  html: string
  page?: number
}

export interface JudgemePrerequisiteRequestForUploadResponse {
  fields: any
  key_prefix: string
  url: string
}

export interface JudgemeGetCustomQuestionInput {
  productId: string
}

export interface JudgemeCustomFormQuestionResponse {
  cf_questions: JudgemeCustomFormQuestion[]
}

export interface JudgemeCustomFormQuestion {
  id: number
  question_type: string
  title: string
  question: string
  required: boolean
  cf_options_count: number
  cf_options: JugdeneCustomFormOption[]
}

export interface JugdeneCustomFormOption {
  id: number
  title: string
}

export interface ProductRecommendationResult {
  compare_at_price: string
  handle: string
  image_src: string
  link_handle: string
  price: string
  product_type: string
  published_at: string
  tags: string
  title: string
  image_width?: string | number
  image_height?: string | number
  mkwc_detail?: string
}

export interface CustomerCreateInput {
  email: string
  firstName?: string
  lastName?: string
  password?: string
  phone?: string
  acceptsMarketing?: boolean
}

export interface CustomerAccessTokenCreateInput {
  email: string
  password: string
}
export interface CustomerResetInput {
  password: string
  resetToken: string
}
export interface CustomerActivateInput {
  password: string
  activationToken: string
}
